import React from 'react'
import { Link } from 'react-router-dom' 
import LazyLoad from 'react-lazyload';
const Footer = () => {
  const currentYear = new Date().getFullYear(); 
  return (
    <>
    <div className="footer">  
       
      <div className="container-fluid fulwidth">
        <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6 ">
            <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/ftr-logo.png" class="img-fluid ftr-logo" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                <p>Nirmala proudly takes the responsibility of providing quality education and formation to more than 3450 girls.</p>
                <div className='social_media'>
                  <ul>
                    <li><a href="#" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/twitter.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad></a></li>
                    <li><a href="https://www.instagram.com/nirmalaconventrajkot?igsh=MWxobHVkNmh3MHp4aw==" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/insta.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad></a></li>
                    <li><a href="#" target="_blank"><LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/facebook.png" alt="Nirmala Convent School, Rajkot" /></LazyLoad></a></li> 
                  </ul>
                </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 divider">
                <div className="quick-link">
                  <h5>Quick links</h5>
                    <ul>
                        <li><Link to="/kindergarten"> <i className="bi bi-caret-right-fill"></i> Kindergarten</Link></li> 
                        <li><Link to="/cbse"> <i className="bi bi-caret-right-fill"></i> CBSE Board </Link></li> 
                        <li><Link to="/erp-software"> <i className="bi bi-caret-right-fill"></i> ERP Software </Link></li>
                        <li><Link to="/parent-portal"> <i className="bi bi-caret-right-fill"></i> Portal (Help)? </Link></li>                     
                        <li><Link to="/"> <i className="bi bi-caret-right-fill"></i>  Sitemap</Link></li>
                        <li><Link to="/career"> <i className="bi bi-caret-right-fill"></i> Career</Link></li> 
                    </ul>
                </div>
                <div className="cont-dtl">
                    <h5>Our Location</h5>
                    <p><Link to="https://maps.app.goo.gl/7gxj29hJd1ZiCZFi9" target="_blank"><i className="bi bi-geo-alt"></i> Nirmala Road, Rajkot - 360007</Link></p>
                    <p><Link to="tel:0281-2966204"><i className="bi bi-telephone-fill"></i> 0281-2966204 / 03</Link></p> 
                    <p><Link to="mailto:ncsrajkot@gmail.com"><i class="bi bi-envelope-fill"></i> ncsrajkot@gmail.com</Link></p> 
                </div>
            </div> 
            <div className="col-xl-6 col-lg-12 col-md-12">
              <div className='d-flex footer-wrap'>
                <div className="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3691.5410962545!2d70.77430397529233!3d22.295366879690196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959ca2619c38e1b%3A0x1ba88031ce26567c!2sNirmala%20Convent%20Rd%2C%20Rajkot%2C%20Gujarat%20360007!5e0!3m2!1sen!2sin!4v1729510875176!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className='d-flex mb-app'>
                <div className='mobile-app'>
                   <h6>Download Mobile App</h6>
                  <p>   CampusCare 10x mobile app for parents is a tool that serves as a single point of reference for parents. The app centralises all the school-parent interaction and lets parents stay updated with their ward's day-to-day activities. Parents can receive assignments, homework, and circulars, keep track of their child's attendance, do online fee payment, view report cards.</p>
                  <div class="footerbtm">
                <div class="mobile-app">
                    <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/app-store.png"/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"><img src="https://webapi.entab.info/api/image/NCSR/public/Images/google-app.png"/></a>
                    <a href="parent-portal" class="phelp"> Portal Help </a>
              </div>
            </div>
                  <p>Use School Code : NCSRAJ</p>
                </div>
                <div>
                <LazyLoad>  <img src="https://webapi.entab.info/api/image/NCSR/public/Images/entabimg.png" className='img-fluid phone-img' alt="Nirmala Convent School, Rajkot"  /></LazyLoad>
                </div>
                </div>
              </div>
            </div>
        </div>
       </div> 
       <div className="copyright">
          <p>©️Copyright 2020 - {currentYear} Nirmala Convent School, Rajkot. All right reserved </p> 
      </div>
    </div> 
    </>
  )
}

export default Footer
