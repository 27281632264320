import React, { useEffect, useState } from "react";
import Header from '../Component/Header'
import Breadcrumb from "../Component/Breadcrumb";
import Footer from '../Component/Footer'
import LazyLoad from 'react-lazyload';
import { form } from "../Service/Api";
import { getHeader } from "../config";
const Career = () => {
    const [formData, setFormData] = useState({
        name: "",
        dob: "",
        address: "",
        phone: "",
        phone1: "",
        webAddress: "",
        email: "",
        fatherName: "",
        profession: "",
        frPhone: "",
        motherName: "",
        profession1: "",
        mrPhone: "",
        spouseName: "",
        profession3: "",
        spPhone: "",
        depName: "",
        profession4: "",
        depPhone: "",
        attachments: null,
        qualification1: [
          { qualification: "", school: "", percent: "", academicYear: "" },
        ],
        careerDtl: [
          { orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" },
        ],
      });
    
      const [errors, setErrors] = useState({});
      const [successMessage, setSuccessMessage] = useState("");
    
      useEffect(() => {
        console.log("Updated Form Data: ", formData);
      }, [formData]);
    
      const showMore = () => {
        setFormData({
          ...formData,
          qualification1: [
            ...formData.qualification1,
            { qualification: "", school: "", percent: "", academicYear: "" },
          ]
          
        });
      };
      const showMore1 = () => {
        setFormData({
          ...formData,
         
          careerDtl: [
            ...formData.careerDtl,
            { orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" },
          ]
        });
      };
    
      const removeQualification = (index) => {
        const updatedQualifications = formData.qualification1.filter((_, idx) => idx !== index);
        setFormData({ ...formData, qualification1: updatedQualifications });
      };
    
      const removeCareerDtl = (index) => {
        const updatedCareerDtl = formData.careerDtl.filter((_, idx) => idx !== index);
        setFormData({ ...formData, careerDtl: updatedCareerDtl });
      };
    
      const handleChange = (e, index = null) => {
        const { name, value, type, files } = e.target;
    
        if (type === "file") {
          setFormData({ ...formData, attachments: files[0] });
          setErrors({ ...errors, attachments: "" });
        } else if (name.startsWith("qualification") || name.startsWith("school") || name.startsWith("percent") || name.startsWith("academicYear")) {
          const updatedQualifications = [...formData.qualification1];
          updatedQualifications[index][name.split("-")[0]] = value;
          setFormData({ ...formData, qualification1: updatedQualifications });
        } else if (name.startsWith("orgnisation") || name.startsWith("designation") || name.startsWith("fromYear") || name.startsWith("toYear") || name.startsWith("experience")) {
          const updatedCareerDtl = [...formData.careerDtl];
          updatedCareerDtl[index][name.split("-")[0]] = value;
          setFormData({ ...formData, careerDtl: updatedCareerDtl });
        } else {
          setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: "" });
      };
    
      const validateForm = () => {
        const newErrors = {};
    
        formData.qualification1.forEach((qualification, index) => {
          if (!qualification.qualification) newErrors[`qualification-${index}`] = "Qualification is required";
          if (!qualification.school) newErrors[`school-${index}`] = "School/Institute is required";
          if (!qualification.percent) newErrors[`percent-${index}`] = "Percentage is required";
          if (!qualification.academicYear) newErrors[`academicYear-${index}`] = "Completion year is required";
        });
    
        formData.careerDtl.forEach((career, index) => {
          if (!career.orgnisation) newErrors[`orgnisation-${index}`] = "Organisation is required";
          if (!career.designation) newErrors[`designation-${index}`] = "Designation is required";
          if (!career.fromYear) newErrors[`fromYear-${index}`] = "From year is required";
          if (!career.toYear) newErrors[`toYear-${index}`] = "To year is required";
          if (!career.experience) newErrors[`experience-${index}`] = "Experience is required";
        });
    
        if (formData.attachments) {
          const validTypes = ["image/jpeg", "image/jpg", "application/pdf"];
          if (!validTypes.includes(formData.attachments.type)) {
            newErrors.attachments = "Invalid file type. Only JPG, JPEG, and PDF are allowed.";
          }
        }
    
        return newErrors;
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
    
        const type = "ABC";
        const formDataToSend = new FormData();
        for (const key in formData) {
          if (key === "qualification1" || key === "careerDtl") {
            formDataToSend.append(key, JSON.stringify(formData[key]));
          } else {
            formDataToSend.append(key, formData[key]);
          }
        }
        formDataToSend.append("type", type);
    
        try {
          const { header } = getHeader();
          await form(formDataToSend, header, type);
          setSuccessMessage("Form submitted successfully.");
          setFormData({
            name: "",
            dob: "",
            address: "",
            phone: "",
            phone1: "",
            webAddress: "",
            email: "",
            fatherName: "",
            profession: "",
            frPhone: "",
            motherName: "",
            profession1: "",
            mrPhone: "",
            spouseName: "",
            profession3: "",
            spPhone: "",
            depName: "",
            profession4: "",
            depPhone: "",
            attachments: null,
            qualification1: [{ qualification: "", school: "", percent: "", academicYear: "" }],
            careerDtl: [{ orgnisation: "", designation: "", fromYear: "", toYear: "", experience: "" }],
          });
        } catch (error) {
          console.error("Error submitting form:", error);
        }
      };
    
    return (
        <>
        <Header />
            <Breadcrumb title="Career" tabName="Career" />
            <div className="innerPage">
                <div className="container">
                    <div className="row">                       
                        <div className="col-md-7">  
                            <p><b>For the following subjects:</b></p>
                            <ul>
                                <li>Physics, Chemistry, Mathematics, Microbiology.(Post Graduates with B. Ed or interested in pursuing B. Ed)</li>
                                <li>Social Studies & English (B.A, B.Ed. or M.A, B.Ed.)</li>
                                <li>Physical Education (M.P.Ed.)</li>
                                <li>Kindergarten: (Graduation with B.Ed. or Montessori)</li>
                            </ul>
                            <p><b>How To Apply for any of the above position, please send your resumes</b></p>
                            <p><b>To CV </b> - <a href="mailto:  hrschoolrecruitment2024@gmail.com"> hrschoolrecruitment2024@gmail.com</a></p>
                            <p><b>OR Contact </b><a href="tel: 9586724824"> 9586724824</a></p>
                            <p><strong>Note:</strong></p>
                            <p> Salary based on qualification, experience and performance in demo class (Attractive salary for deserving candidates)</p> 
                            <div class="clr"></div>
                            <p><b>Managing Trustee</b></p>
                            <p>NIRMALA CONVENT SCHOOL<br /> Nirmala Road, Rajkot - 360 001</p>
                            {/* <div>
                                <a href="https://webapi.entab.info/api/image/NCSR/public/pdf/teacher-job-application.docx" download><button className='readMore m-2'>Job Application Form in Doc</button></a>
                                <a href="https://webapi.entab.info/api/image/NCSR/public/pdf/teacher-job-application1.pdf" download><button className='readMore m-2'>Job Application Form in Pdf</button></a>
                            </div> */}
                        </div> 
                        <div className="col-md-5">
                        <LazyLoad><img src="https://webapi.entab.info/api/image/NCSR/public/Images/career.jpg" className="img-fluid" alt="Nirmala Convent School, Rajkot" /></LazyLoad>
                        </div>
                        <div class="col-lg-12 mt-4">
                        <h3 className="text-center">APPLICATION FORM FOR TEACHING POST</h3>
                           <div className="career-form">
                            
                           <form className='careerform-feilds' onSubmit={handleSubmit}>
            <div className="row">
           
              {/* Name */}
              <div className="col-lg-3">
                <label>Applicant Name</label>
                <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange} />
                {errors.name && <div className="error">{errors.name}</div>}
              </div>

              {/* Address */}
              <div className="col-lg-3">
                <label>Date Of Birth</label>
                <input type="date" name="dob" className="form-control" value={formData.dob} onChange={handleChange} />
                {errors.dob && <div className="error">{errors.dob}</div>}
              </div>

              {/* Date of Birth */}
              <div className="col-lg-6">
                <label>Residential Adress</label>
                <input type="text" name="address" className="form-control" value={formData.address} onChange={handleChange} />
                {errors.address && <div className="error">{errors.address}</div>}
              </div>
{/* Phone */}
<div className="col-lg-3">
                <label>Mobile Number</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone" className="form-control" maxLength={10} value={formData.phone} onChange={handleChange} />
                {errors.phone && <div className="error">{errors.phone}</div>}
              </div>
{/* Phone */}
<div className="col-lg-3">
                <label>Alternative Number</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="phone1" className="form-control" maxLength={10} value={formData.phone1} onChange={handleChange} />
                {errors.phone1 && <div className="error">{errors.phone1}</div>}
              </div>
{/* Phone */}
<div className="col-lg-3">
                <label>Fixed Line</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="webAddress" className="form-control" maxLength={10} value={formData.webAddress} onChange={handleChange} />
                {errors.webAddress && <div className="error">{errors.webAddress}</div>}
              </div>
              {/* Email */}
              <div className="col-lg-3">
                <label>Email</label>
                <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>

              </div>
              <div className="row">
                <div className="col-lg-12">
<h3>Family Details:</h3>
</div>
              {/* Father/Spouse Name */}
              <div className="col-lg-4">
                <label>father's Name</label>
                <input type="text" name="fatherName" className="form-control" value={formData.fatherName} onChange={handleChange} />
                {errors.fatherName && <div className="error">{errors.fatherName}</div>}
              </div>
              <div className="col-lg-4">
                <label>Father Occupation/Designation</label>
                <input type="text" name="profession" className="form-control" value={formData.profession} onChange={handleChange} />
                {errors.profession && <div className="error">{errors.profession}</div>}
              </div>
              <div className="col-lg-4">
                <label>Father Contact Number</label> 
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="frPhone" className="form-control" maxLength={10} value={formData.frPhone} onChange={handleChange} />
                {errors.frPhone && <div className="error">{errors.frPhone}</div>}
              </div>
            
            
            
              <div className="col-lg-4">
                <label>Mother's Name</label>
                <input type="text" name="motherName" className="form-control" value={formData.motherName} onChange={handleChange} />
                {errors.motherName && <div className="error">{errors.motherName}</div>}
              </div>
              <div className="col-lg-4">
                <label>Mother Occupation/Designation</label>
                <input type="text" name="profession1" className="form-control" value={formData.profession1} onChange={handleChange} />
                {errors.profession1 && <div className="error">{errors.profession1}</div>}
              </div>
              <div className="col-lg-4">
                <label>Mother Contact Number</label> 
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="mrPhone" className="form-control" maxLength={10} value={formData.mrPhone} onChange={handleChange} />
                {errors.mrPhone && <div className="error">{errors.mrPhone}</div>}
              </div>
              
              <div className="col-lg-4">
                <label>Spouse's Name</label>
                <input type="text" name="spouseName" className="form-control" value={formData.spouseName} onChange={handleChange} />
                {errors.spouseName && <div className="error">{errors.spouseName}</div>}
              </div>
              <div className="col-lg-4">
                <label>Spouse Occupation/Designation</label>
                <input type="text" name="profession3" className="form-control" value={formData.profession3} onChange={handleChange} />
                {errors.profession3 && <div className="error">{errors.profession3}</div>}
              </div>
              <div className="col-lg-4">
                <label>Spouse Contact Number</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="spPhone" className="form-control" maxLength={10} value={formData.spPhone} onChange={handleChange} />
                {errors.spPhone && <div className="error">{errors.spPhone}</div>}
              </div>

              <div className="col-lg-4">
                <label>Dependent's Name</label>
                <input type="text" name="depName" className="form-control" value={formData.depName} onChange={handleChange} />
                {errors.depName && <div className="error">{errors.depName}</div>}
              </div>
              <div className="col-lg-4">
                <label>Dependent Occupation/Designation</label>
                <input type="text" name="profession4" className="form-control" value={formData.profession4} onChange={handleChange} />
                {errors.profession4 && <div className="error">{errors.profession4}</div>}
              </div>
              <div className="col-lg-4">
                <label>Dependent Contact Number</label>
                <input type="tel" pattern="\d{10}" inputMode="numeric" name="depPhone" className="form-control" maxLength={10} value={formData.depPhone} onChange={handleChange} />
                {errors.depPhone && <div className="error">{errors.depPhone}</div>}
              </div>

            </div>

            {/* Educational Qualification */}
            <h3>Educational Credentials: <span onClick={showMore}><i className="bi bi-plus"></i></span></h3>
            {formData.qualification1.map((qualification, index) => (
              <div className="row pos-rel" key={index}>
                {/* Qualification */}
                <div className="col-lg-4">
                  <label>Qualification</label>
                  <input
                    type="text"
                    name={`qualification-${index}`}
                    className="form-control"
                    value={qualification.qualification}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`qualification-${index}`] && <div className="error">{errors[`qualification-${index}`]}</div>}
                </div>

                {/* Subject */}
                <div className="col-lg-4">
                  <label>Institute/University/School with Board</label>
                  <input
                    type="text"
                    name={`school-${index}`}
                    className="form-control"
                    value={qualification.school}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`school-${index}`] && <div className="error">{errors[`school-${index}`]}</div>}
                </div>

                {/* Marks */}
                <div className="col-lg-4">
                  <label>Percentage/Grade</label>
                  <input
                    type="text"
                    name={`percent-${index}`}
                    className="form-control"
                    value={qualification.percent}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`percent-${index}`] && <div className="error">{errors[`percent-${index}`]}</div>}
                </div>

                {/* Percentage */}
                <div className="col-lg-4">
                  <label>Percentage</label>
                  <input
                    type="text"
                    name={`rate-${index}`}
                    className="form-control"
                    value={qualification.rate}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`rate-${index}`] && <div className="error">{errors[`rate-${index}`]}</div>}
                </div>

                {/* Institution */}
                <div className="col-lg-4">
                  <label>Year of Completion</label>
                  <input
                    type="text"
                    name={`academicYear-${index}`}
                    className="form-control"
                    value={qualification.academicYear}
                    onChange={(e) => handleChange(e, index)}
                  />
                  {errors[`academicYear-${index}`] && <div className="error">{errors[`academicYear-${index}`]}</div>}
                </div>

                {/* Remove Qualification */}
                {index > 0 && (
                  <div className="col-lg-12">
                    <button type="button" onClick={() => removeQualification(index)} className="removebtn">
                      - 
                    </button>
                  </div>
                )}
              </div>
            ))}
            {/* Educational Qualification */}
            <h3>
  Career Highlights: 
  <span onClick={showMore1}>
    <i className="bi bi-plus"></i>
  </span>
</h3>
{formData.careerDtl.map((careerDetail, index) => (
  <div className="row pos-rel" key={index}>
    {/* Organisation */}
    <div className="col-lg-8">
      <label>Organisation Name with Place</label>
      <input
        type="text"
        name={`orgnisation-${index}`}
        className="form-control"
        value={careerDetail.orgnisation} // Correct reference
        onChange={(e) => handleChange(e, index)}
      />
      {errors[`orgnisation-${index}`] && <div className="error">{errors[`orgnisation-${index}`]}</div>}
    </div>

    {/* Designation */}
    <div className="col-lg-4">
      <label>Position / Designation</label>
      <input
        type="text"
        name={`designation-${index}`}
        className="form-control"
        value={careerDetail.designation}
        onChange={(e) => handleChange(e, index)}
      />
      {errors[`designation-${index}`] && <div className="error">{errors[`designation-${index}`]}</div>}
    </div>

    {/* From Year */}
    <div className="col-lg-4">
      <label>From</label>
      <input
        type="date"
        name={`fromYear-${index}`}
        className="form-control"
        value={careerDetail.fromYear}
        onChange={(e) => handleChange(e, index)}
      />
      {errors[`fromYear-${index}`] && <div className="error">{errors[`fromYear-${index}`]}</div>}
    </div>

    {/* To Year */}
    <div className="col-lg-4">
      <label>To</label>
      <input
        type="date"
        name={`toYear-${index}`}
        className="form-control"
        value={careerDetail.toYear}
        onChange={(e) => handleChange(e, index)}
      />
      {errors[`toYear-${index}`] && <div className="error">{errors[`toYear-${index}`]}</div>}
    </div>

    {/* Experience */}
    <div className="col-lg-4">
      <label>Experience in YY-MM</label>
      <input
        type="text"
        name={`experience-${index}`}
        className="form-control"
        value={careerDetail.experience}
        onChange={(e) => handleChange(e, index)}
      />
      {errors[`experience-${index}`] && <div className="error">{errors[`experience-${index}`]}</div>}
    </div>

    {/* Remove Career */}
    {index > 0 && (
      <div className="col-lg-12">
        <button type="button" onClick={() => removeCareerDtl(index)} className="removebtn">
          -
        </button>
      </div>
    )}
  </div>
))}
 <div className="col-lg-4">
                <label>Upload CV</label>
                <input
                  className="form-control"
                  type="file"
                  name="attachments"
                  accept=".jpg, .jpeg, .pdf"
                  onChange={handleChange}
                />
                {errors.attachments && <div className="error">{errors.attachments}</div>}
              </div>

            {/* Work Experience */}
          

            {/* Submit Button */}
            <div className="submit">
            <input type="submit" value="Submit" />
          </div>
          </form>

          {/* Success Message */}
          {successMessage && (
            <div className="success-message">
              <p>{successMessage}</p>
            </div>
          )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Career;